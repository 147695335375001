import { useSite } from '@on3/ui-lib/index';
import { SiteLogo } from 'components/Image/SiteLogo';
import { Text } from 'components/UI/Typography/Text';

import styles from './SEOHomeText.module.scss';

export const SEOHomeText = () => {
  const { currentSite } = useSite();

  if (!currentSite?.tagLine) {
    return null;
  }

  return (
    <Text className={styles.metaText}>
      <SiteLogo className={styles.metaIcon} slug="-logo-full" width={42} />
      {currentSite?.tagLine}
    </Text>
  );
};
