import type {
  HeadlineResponse,
  HeadlineResponsePagedData,
  LatestForumPostsResponse,
  RelatedScheduleResponse,
  ScoreboardResponse,
  SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData,
  UserProfileResponseRecord,
} from '@on3/api';
import { useSite } from '@on3/ui-lib/index';
import { MPU } from 'components/Ads/MPU';
import { Forums } from 'components/Forums/Forums';
import { FeedCarousel } from 'components/Headlines/ArticleCarousel/FeedCarousel';
import { CondensedList } from 'components/Headlines/ArticleCondensed/CondensedList';
import { ArticlesList } from 'components/Headlines/ArticleLarge/ArticlesList';
import { PremiumArticleList } from 'components/Headlines/PremiumArticle/PremiumArticleList';
import { SiteLogo } from 'components/Image/SiteLogo';
import { HomeLayout } from 'components/Layouts/HomeLayout';
import { ScheduleModule } from 'components/Schedules/Sidebar/ScheduleModule';
import { TeamConnect } from 'components/Social/TeamConnect';
import { PodcastPlayer } from 'components/Teams/Podcast/PodcastPlayer';
import { TextLink } from 'components/UI/Link/TextLink';
import { useAds } from 'utils/hooks/useAds';

import styles from './FeedHome.module.scss';
import { SEOHomeText } from './SEOHomeText';

export interface IFeedHomeProps {
  featuredArticles?: HeadlineResponse[];
  mainFeed?: HeadlineResponsePagedData;
  channelSports?: HeadlineResponse[];
  channelRecruiting?: HeadlineResponse[];
  featuredAuthors?: UserProfileResponseRecord[];
  premiumArticles?: SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData;
  messageBoard?: LatestForumPostsResponse;
  teamSchedule?: {
    list?: ScoreboardResponse[];
    relatedModel?: RelatedScheduleResponse;
  };
}

const podcastData = {
  allHref:
    '/teams/kentucky-wildcats/news/your-guide-on-how-to-listen-to-the-ksr-podcast-network/',
  src: 'https://playlist.megaphone.fm/?p=LRL4571054944',
};

export const FeedHome = ({
  mainFeed,
  featuredArticles,
  channelSports,
  channelRecruiting,
  premiumArticles,
  messageBoard,
  teamSchedule,
}: IFeedHomeProps) => {
  const { currentSite } = useSite();

  useAds({ pageType: 'home', incUnits: 5 });

  return (
    <>
      <div className={styles.ksrBanner}>
        <TextLink href={`${currentSite?.url}/`}>
          <SiteLogo className={styles.logo} width={50} />
        </TextLink>
      </div>
      {featuredArticles?.length && (
        <FeedCarousel list={featuredArticles.slice(0, 4)} />
      )}
      <HomeLayout>
        <SEOHomeText />
        <section className={styles.feed}>
          {mainFeed?.list?.length && (
            <ArticlesList
              list={mainFeed.list.slice(0, 25)}
              pagination={mainFeed.pagination}
            />
          )}
        </section>
        <aside className={styles.sidebarright}>
          <MPU className={styles.sticky1} id="mpu_top" />
          {!!premiumArticles?.list?.length && (
            <PremiumArticleList
              list={premiumArticles?.list}
              relatedModel={premiumArticles?.relatedModel}
            />
          )}
          {!messageBoard?.posts?.length || <Forums list={messageBoard} />}
          {!!teamSchedule?.list?.length && (
            <ScheduleModule
              list={teamSchedule?.list}
              relatedModel={teamSchedule?.relatedModel}
            />
          )}
          <MPU className={styles.sticky2} id="mpu_middle" />
        </aside>
        <aside className={styles.sidebarleft}>
          {!channelSports?.length || (
            <CondensedList
              list={channelSports.slice(0, 5)}
              secondaryList={channelRecruiting?.slice(0, 5)}
            />
          )}
          <PodcastPlayer data={podcastData} />

          <div className={styles.sticky1}>
            <TeamConnect />
          </div>
        </aside>
      </HomeLayout>
    </>
  );
};
